import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import jwt_decoded from "jwt-decode";

// Route
import PrivateRoute from "./components/PrivateRoute";

// Style
import { Container, CssBaseline, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./App.css";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import AboutUs from "./pages/main/AboutUs";
import ChangePassword from "./pages/menu/ChangePassword";
import Deposit from "./pages/main/Deposit";
import DepositModal from "./modals/DepositModal";
import FAQs from "./pages/main/FAQs";
import Main from "./pages/main/Main";
import News from "./pages/main/News";
import Notification from "./pages/main/Notification";
import StatementDetails from "./pages/main/StatementDetails";
import Statistics from "./pages/main/Statistics";
import Profile from "./pages/menu/Profile";
import Promotions from "./pages/main/Promotions";
import Staking from "./pages/main/Staking";
import Team from "./pages/main/Team";
import TeamLevel from "./pages/main/TeamLevel";
import Wallet from "./pages/menu/Wallet";
import Withdraw from "./pages/main/Withdraw";
import WithdrawModal from "./modals/WithdrawModal";
import MyBonus from "./pages/main/MyBonus";
import Confirm from "./pages/auth/Confirm";
import ForgotPassword from "./pages/menu/ForgotPassword";
import ContactUs from "./pages/auth/ContactUs";
import SendPassToMail from "./pages/menu/SendPassToMail";
import RefBonus from "./pages/RefBonus";
import PrivateKey from "./pages/menu/PrivateKey";

// Utils
import setAuthToken from "./utils/setAuthToken";

// Redux
import store from "./redux/store";
import { logOutUser, setCurrentUser } from "./redux/actions/authActions";

const theme = createTheme({
  palette: {
    primary: { main: "#ffce00" },
    secondary: { main: "#444444" },
    text: { third: "#ffce00" },
  },
});

const App = () => {
  React.useEffect(() => {
    if (localStorage.token) {
      const token = localStorage.token;
      setAuthToken(localStorage.token);
      const decoded = jwt_decoded(localStorage.token);
      store.dispatch(setCurrentUser({ decoded, token }));

      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        store.dispatch(logOutUser());

        window.location.href = "/signin";
      }
    }
  }, []);

  const [depositOpen, setDepositOpen] = React.useState(false);
  const [withdrawOpen, setWithdrawOpen] = React.useState(false);

  const handleDepositOpen = () => setDepositOpen(true);
  const handleDepositClose = () => setDepositOpen(false);
  const handleWithdrawOpen = () => setWithdrawOpen(true);
  const handleWithdrawClose = () => setWithdrawOpen(false);

  return (
    <div className="App" style={{ background: "#ffffff", minHeight: "100vh" }}>
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <Container
            component="main"
            sx={{ zIndex: 1, marginTop: 14 }}
            maxWidth="sm"
          >
            <CssBaseline />
            <Box sx={{ mt: 4 }}>
              <Routes>
                <Route
                  path="/home"
                  element={
                    <PrivateRoute
                      handleDepositOpen={handleDepositOpen}
                      handleWithdrawOpen={handleWithdrawOpen}
                      component={Main}
                    />
                  }
                />
                <Route path="/" element={<SignIn />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route
                  path="/about_us"
                  element={<PrivateRoute component={AboutUs} />}
                />
                <Route
                  path="/change_password"
                  element={<PrivateRoute component={ChangePassword} />}
                />
                <Route
                  path="/deposit"
                  element={<PrivateRoute component={Deposit} />}
                />
                <Route
                  path="/faqs"
                  element={<PrivateRoute component={FAQs} />}
                />
                <Route
                  path="/news"
                  element={<PrivateRoute component={News} />}
                />
                <Route
                  path="/statement_details"
                  element={<PrivateRoute component={StatementDetails} />}
                />
                <Route
                  path="/statistics"
                  element={<PrivateRoute component={Statistics} />}
                />
                <Route
                  path="/notification"
                  element={<PrivateRoute component={Notification} />}
                />
                <Route
                  path="/profile"
                  element={<PrivateRoute component={Profile} />}
                />
                <Route
                  path="/private_key"
                  element={<PrivateRoute component={PrivateKey} />}
                />
                <Route
                  path="/promotions"
                  element={<PrivateRoute component={Promotions} />}
                />
                <Route
                  path="/staking"
                  element={<PrivateRoute component={Staking} />}
                />
                <Route
                  path="/connect_with_us"
                  element={<PrivateRoute component={ContactUs} />}
                />
                <Route
                  path="/team"
                  element={<PrivateRoute component={Team} />}
                />
                <Route
                  path="/team_level"
                  element={<PrivateRoute component={TeamLevel} />}
                />
                <Route
                  path="/withdraw"
                  element={<PrivateRoute component={Withdraw} />}
                />
                <Route path="/forgot-password" element={<SendPassToMail />} />
                <Route path="/confirm/:token" element={<Confirm />} />
                <Route
                  path="/reset-password/:token"
                  element={<ForgotPassword />}
                />
                <Route
                  path="/wallet"
                  element={
                    <PrivateRoute
                      handleDepositOpen={handleDepositOpen}
                      handleWithdrawOpen={handleWithdrawOpen}
                      component={Wallet}
                    />
                  }
                />
                <Route
                  path="/my-bonus"
                  element={<PrivateRoute component={MyBonus} />}
                />
                <Route
                  path="/ref-bonus"
                  element={<PrivateRoute component={RefBonus} />}
                />
              </Routes>
            </Box>
          </Container>
          <Footer />
          <WithdrawModal
            open={withdrawOpen}
            handleClose={handleWithdrawClose}
          />
          <DepositModal open={depositOpen} handleClose={handleDepositClose} />
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default App;
