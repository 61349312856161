import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SwalAlert } from "../../swal/SwalAlert";
import "sweetalert2/src/sweetalert2.scss";

import { getUserById } from "../../redux/actions/userActions";

const shortenAddress = (address, chars) => {
  if (address) return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};

const PrivateKey = ({ getUserById, users }) => {
  const { user } = users;

  React.useEffect(() => {
    getUserById();
  }, [getUserById]);

  return (
    <div className="p-8 border-amber-400 border-[2px] items-center justify-center rounded-lg">
      <Typography
        component="h1"
        variant="h5"
        align="center"
        color="text.secondary"
      >
        Private Key
      </Typography>
      <div className="flex gap-2 mt-6 justify-center items-center">
        <span>{user ? shortenAddress(user.privateKey, 5) : "Loading..."}</span>
        <ContentCopyIcon
          color="primary"
          sx={{ ml: 1 }}
          className="cursor-pointer"
          onClick={() => {
            SwalAlert.fire(
              "Copied!",
              "Private key was copied, Keep it safe.",
              "success"
            );
            navigator.clipboard.writeText(user ? user.privateKey : "");
          }}
        />
      </div>
    </div>
  );
};

PrivateKey.propTypes = {
  getUserById: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, { getUserById })(PrivateKey);
